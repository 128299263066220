module.exports = [{
  plugin: require('/tmp/223dcaf5/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
  options: {
    "plugins": [],
    "gatsbyRemarkPlugins": ["gatsby-remark-import-code"],
    "remarkPlugins": [null, null]
  }
}, {
  plugin: require('/tmp/223dcaf5/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/tmp/223dcaf5/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/tmp/223dcaf5/node_modules/gatsby-theme-mdx-deck/gatsby-browser.js'),
  options: {
    "plugins": [],
    "cli": true,
    "contentPath": "/tmp/223dcaf5/deck.mdx"
  }
}];